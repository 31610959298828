<template>
  <v-col
    class="my-12 text-center"
    cols="12"
  >
    <v-slide-y-reverse-transition>
      <div
        v-if="loadingInformation"
        class="d-flex justify-center"
      >
        <div class="info-text">
          <p
            v-if="isLegacyOnboarding"
            class="font-weight-black mb-4"
          >
            {{ loadingInformation?.title }}
          </p>
          <h1
            v-else
            class="display-1 font-weight-black mb-4"
          >
            {{ loadingInformation?.title }}
          </h1>
          <p
            class="mb-8"
            :style="{'width': $vuetify.breakpoint.smAndDown ? '100%': '800px'}"
            v-html="loadingInformation?.subtitle"
          />
        </div>
      </div>
    </v-slide-y-reverse-transition>

    <div class="w-full d-flex justify-space-around">
      <v-progress-circular
        indeterminate
        :size="50"
        :width="5"
        color="primary"
      />
    </div>
  </v-col>
</template>

<script>
import brandingMixin from '@/mixins/branding'

export default {
  inject: ['billingOnboardingDetailsData'],
  mixins: [brandingMixin],
  data () {
    return {
      timeout: null,
      loadingInformationKey: ''
    }
  },
  unmounted () {
    clearTimeout(this.timeout)
  },
  computed: {
    loadingInformation () {
      const loadingTexts = {
        idle: {
          title: this.$t('registration.loading.idle.title', [this.$auth.user.name]),
          subtitle: this.$t('registration.loading.idle.subtitle')
        },
        setup: {
          title: this.$t('registration.loading.setup.title', [this.$auth.user.name]),
          subtitle: this.$t('registration.loading.setup.subtitle')
        },
        tip: {
          title: this.$t('registration.loading.tip.title'),
          subtitle: this.$t('registration.loading.tip.subtitle')
        }
      }

      return loadingTexts[this.loadingInformationKey]
    },
    isLegacyOnboarding () {
      return this.billingOnboardingDetailsData?.isSignupComplete
    }
  },
  mounted () {
    this.loadingInformationKey = 'setup'
    this.animationTimeout = setTimeout(() => {
      this.loadingInformationKey = ''
      if (this.isLegacyOnboarding) {
        this.$emit('completed')
      }
    }, 6500)
    if (!this.isLegacyOnboarding) {
      this.informationTimeout = setTimeout(() => {
        this.loadingInformationKey = 'tip'
      }, 7000)
      this.responseTimeout = setTimeout(() => {
        this.$emit('completed')
      }, 15000)
    }
  }

}
</script>

<style>

</style>
