<template>
  <div class="pa-12">
    <h1 class="text-center mb-10">
      {{ $t('payment.successful-payment-title-create-billing') }}
    </h1>
    <div class="text-center">
      <v-progress-circular
        indeterminate
        :size="200"
        :width="15"
        color="primary"
      />
    </div>
    <p class="text-center mt-10">
      {{ $t('payment.successful-payment-info-create-billing') }}
    </p>
  </div>
</template>
<script>
export default {}
</script>
